<script lang="ts">
	export let title: string;
	export let description: string;
</script>

<div
	class="card card-bordered card-compact border-2 border-base-100 bg-base-200/85 backdrop-blur-sm shadow-xl max-w-screen-sm"
>
	<div class="card-body gap-0 prose-sm sm:prose-lg">
		<strong class="pr-10">{title}</strong>
		<p>{@html description}</p>
	</div>
</div>
