<script lang="ts" context="module">
	import Icon from '@iconify/svelte';
	import { createToaster, type Toast } from '@melt-ui/svelte';
	import { flip } from 'svelte/animate';
	import ToastSimple from '$lib/components/toasts/ToastSimple.svelte';
	import { fly } from 'svelte/transition';

	const {
		elements: { content, close },
		helpers: { addToast: _addToast, removeToast: _removeToast },
		states: { toasts: _toasts },
		actions: { portal }
	} = createToaster<{
		ref: any;
		close: boolean;
		props: Record<string, any>;
	}>();

	export const toasts = _toasts;

	const map = new Map<string, Toast>();

	export type ToastOptions = {
		close: boolean;
		type: 'foreground' | 'background';
		closeDelay: number;
		props: Record<string, any>;
	};

	export function addToastComponent(
		id: string,
		ref: any,
		options: Partial<ToastOptions> = {}
	):
		| Toast<{
				ref: any;
				close: boolean;
		  }>
		| false {
		if (map.has(id)) return false;

		const toast = _addToast({
			data: {
				ref,
				close: typeof options.close == 'undefined' ? true : options.close,
				props: options.props || {}
			},
			closeDelay: options.closeDelay || 0,
			type: options.type || 'foreground'
		});
		map.set(id, toast);
		return toast;
	}

	export function addToastSimple(
		id: string,
		title: string,
		description: string,
		options: Partial<ToastOptions> = {}
	):
		| Toast<{
				ref: any;
				close: boolean;
		  }>
		| false {
		if (map.has(id)) return false;

		const toast = _addToast({
			data: {
				ref: ToastSimple,
				close: typeof options.close == 'undefined' ? true : options.close,
				props: {
					title,
					description
				}
			},
			closeDelay: options.closeDelay || 0,
			type: options.type || 'foreground'
		});
		map.set(id, toast);
		return toast;
	}

	export function removeToast(id: string) {
		const toast = map.get(id);
		if (!toast) return false;
		map.delete(id);
		_removeToast(toast.id);
	}
</script>

<div
	class="fixed right-0 m-4 flex flex-col items-end gap-2 bottom-0 top-auto"
	style:z-index="60"
	use:portal
>
	{#each $_toasts as { id, data } (id)}
		<div
			{...$content(id)}
			use:content
			animate:flip={{ duration: 500 }}
			in:fly={{ duration: 300, x: '100%' }}
			out:fly={{ duration: 300, x: '100%' }}
			class="relative min-w-40 bottom-0"
		>
			<svelte:component this={data.ref} {...data.props} />
			{#if data.close}
				<button
					{...$close(id)}
					use:close
					on:click={() => removeToast(id)}
					class="absolute right-4 top-4 btn btn-circle btn-sm btn-ghost"
				>
					<Icon icon="ci:close-md" width="16" height="16" />
				</button>
			{/if}
		</div>
	{/each}
</div>
